export const getCurrency = (currency) => {
	if (currency === 'USD') {
		return '$';
	} else if (currency === 'VND') {
		return '₫';
	} else if (currency === 'INR') {
		return '₹';
	} else {
		return '₫';
	}
};